<template>
  <v-snackbar
    v-model="snackbar"
    vertical
    max-height="600px"
    :timeout="7000"
    multi-line>
    <v-icon
      color="#fff"
      small
      style="position: absolute; right: 5px; top: 5px"
      @click="snackbar = false">
      mdi-close
    </v-icon>
    <p>
      Suggestion has already been sent earlier to {{ userTitle }}
    </p>
    <ul>
      <li
        v-for="user in users"
        :key="user">
        {{ user }}
      </li>
    </ul>
    <v-btn
      color="lightBlue"
      class="ml-auto mt-5"
      @click="suggestAgain">
      Suggest Again
    </v-btn>
  </v-snackbar>
</template>
<script>
import {
  mapGetters,
  mapMutations,
  mapActions,
} from 'vuex';
export default {
  name: 'SuggestDialogSnackbar',
  data() {
    return {
      text: 'TEST',
    };
  },
  computed: {
    ...mapGetters('Collections', ['getSuggestionErrorSnackbarDialogVal', 'getSuggestionErrorSnackbarData']),
    users() {
      return this.getSuggestionErrorSnackbarData?.users || [];
    },
    userTitle() {
      return this.usersLength <= 1 ? 'user' : 'users';
    },
    usersLength() {
      return this.users.length;
    },
    snackbar: {
      get() {
        return this.getSuggestionErrorSnackbarDialogVal;
      },
      set(open) {
        this.setSuggestionErrorSnackbar({
          open,
        });
      },
    },
  },
  methods: {
    ...mapMutations('Collections', ['setSuggestionErrorSnackbar']),
    ...mapActions('Collections', ['suggestCollection']),
    suggestAgain() {
      const { emailMessage, collectionId } = this.getSuggestionErrorSnackbarData || {
      };
      this.suggestCollection({
        selectedUsers: this.users,
        emailMessage,
        collectionId,
        force: true,
      });
    },
  },
};
</script>
<style scoped lang="scss">
  .v-snack {
    &__wrapper {
      ul {
        word-break: break-word;
        max-height: 400px;
        overflow-y: auto;
      }
      p {
        font-size: 16px;
        color: #fff;
      }
    }
  }
</style>
